<template>
    <div>
        <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Citas de Consulta Externa</v-toolbar-title>
        </v-toolbar>
        <v-data-table
            elevation="2"
            :headers="headers"
            :items="datos"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loadingDatosTodos"

            :server-items-length='totalRegistros'
            :options.sync="optionsTable"
        >
            <template v-slot:[`item.medico`]="{ item }">
                <div v-html="item.medico"></div>
            </template>

            <template v-slot:[`item.citaId`]="{ item }">
                <div>{{item.citaId}}</div>
                <v-icon v-if="item.horaEgreso" color="orange">mdi-exit-run</v-icon>
            </template>

            <template v-slot:[`item.tipo`]="{ item }">
                <v-chip :color="esteTipo(item.tipoId).color" class="white--text">{{esteTipo(item.tipoId).tipo}}</v-chip>
            </template>

            <template v-slot:[`item.ver`]="{ item }">
                <v-icon @click="$router.push({path:`/hospital/interna/`+item.id})">mdi-magnify</v-icon>
            </template>
            <!--
                <template v-slot:[`item.areaNom`]="{ item }">
                    <div>{{item.areaNom}}</div>
                    <div>({{item.servicioNom}})</div>
                </template>

                <template v-slot:[`item.veInter`]="{ item }">
                    <v-icon @click="$router.push({path:`/hospital/interna/`+item.id})">mdi-folder</v-icon>
                </template>
            -->

        </v-data-table>

    </div>
</template>

<script>
export default {
    created(){
        //this.carga()
    },
    props:{
        dp:Object,
        tab: Number,
        esteTab:Number,
    },
    data:()=>({
        params:{},
        headers:[
            {text:'Cita', value:'citaId', width:'10%', align:'center'},
            {text:'Fecha', value:'fecha', width:'15%'},
            {text:'Medico', value:'medico'},
            {text:'Tipo', value:'tipo', width:'10%', align:'center'},

            {text:'', value:'ver', width:'5%'},
        ],

        datos:[],
        loadingDatosTodos:false,
        optionsTable:{},
        totalRegistros:0,
    }),

    methods:{
        async carga(){

            //this.params={...this.optionsTable}
            this.params.pacienteForm = this.dp.pacienteId
            this.params.start=(this.optionsTable.page-1 )*this.optionsTable.itemsPerPage
            this.params.limit=this.optionsTable.itemsPerPage
            this.loadingDatosTodos=true
            try {
                let req = await this.$http({
                    url: '/reportes/pacientesCeReport',
                    method:'GET',
                    params: this.params,
                })
                this.loadingDatosTodos=false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch (err) {
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        esteTipo(id){
            let tipo = '?'
            let color="black"

            switch (id){
                case 1:
                    tipo='Primera vez'
                    color='orange'
                    break
                case 2:
                    tipo='Subsecuente'
                    color="green"
                    break
                case 3:
                    tipo='Especial'
                    color="blue"
                    break
                case 5:
                    tipo='Cancelada'
                    color="red"
                    break
            }
            return({tipo:tipo, color:color})
        },
    },

    watch:{
        tab(){
            if(this.tab == this.esteTab){
                this.carga()
            }
        },
        optionsTable: {
            handler () {
                //console.log(this.optionsTable)
                this.carga()
            },
            deep: true,
        },
    },
}
</script>

<style>

</style>